import ng from 'angular';
import acpCore from 'core';
import acpPanCvcBlockComponent from 'components/pan-cvc-block';
import acpVirtualCardsDomainModule from 'components/virtual-cards-domain';
import { acpVirtualCardContainerComponent } from './acp-virtual-cards-container-component';
import { acpVirtualCardAdd } from './acp-virtual-cards-add-component';
import acpVirtualCardsContent from './virtual-cards-content.yml';
import acpVirtualCardsInactiveCardsComponent from './acp-virtual-cards-inactive-card-component';

import './styles/${theme}/core.scss';
import { acpComponentApptentiveDomain } from 'components/apptentive-domain';

var component = ng
  .module('acp.component.virtual-cards', [
    acpCore.name,
    acpPanCvcBlockComponent.name,
    acpVirtualCardsDomainModule.name,
    acpComponentApptentiveDomain.name
  ])
  .component('acpVirtualCardsAdd', acpVirtualCardAdd)
  .component(
    'acpVirtualCardsInactiveCards',
    acpVirtualCardsInactiveCardsComponent
  )
  .component('acpVirtualCardsContainer', acpVirtualCardContainerComponent)
  .run(
    /*@ngInject*/ function (contentSectionCache) {
      contentSectionCache.put(
        'components/virtual-cards',
        acpVirtualCardsContent
      );
    }
  );
export * from './types';
export default component;
