import acpVirtualCardContainerTemplate from './templates/acp-virtual-cards-container.html';

export class AcpVirtualCardContainerComponentCtrl
  implements nsUtils.NsComponentController {
  public busy: boolean;
  public cards;
  public isLanding: boolean;
  public hideVirtualCardSection: boolean;

  constructor(
    nsComponentDecorator,
    private acpVirtualCardsModel,
    private acpCoreDispatcher: any,
    private nsPermissions: nsUtils.NsPermissionsService,
    private ACP_VIRTUAL_CARDS_CONSTANTS: any
  ) {
    'ngInject';

    // If methods such as $onValue and $tie are needed:
    nsComponentDecorator(this, this);
  }

  public $onInit() {
    // Perform initialization here
    this.busy = true;
    this.handleListener();
    this.acpVirtualCardsModel.getAllPhysicalCards(true);
    this.redirectTo().then((isLanding) => {
      this.isLanding = isLanding;
      this.busy = false;
    });
    this.virtualCardSection().then((data) => {
      this.hideVirtualCardSection = data;
    });
  }

  public $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);

  private async redirectTo(): Promise<boolean> {
    try {
      const isVirtualCardAllowed: boolean = await this.nsPermissions.requestPermission(
        'hasVirtualDebitCardsAllowed'
      );
      if (!isVirtualCardAllowed) {
        throw new VirtualCardNotSupported('Virtual card not supported');
      }
      const virtualCards = await this.acpVirtualCardsModel.getAllVirtualCards();
      const activeVirtualCards = this.acpVirtualCardsModel.getFilteredCard(
        'active'
      );
      if (
        virtualCards.length >=
          this.ACP_VIRTUAL_CARDS_CONSTANTS.MAX_CARD_ALLOWED ||
        (activeVirtualCards && activeVirtualCards.length)
      ) {
        return true;
      }
      return false;
    } catch (error) {
      if (!(error instanceof VirtualCardNotSupported)) {
        this.acpCoreDispatcher.toast.show.emit({
          toastClass: 'failure',
          content: 'virtual-card-something-wrong:components/virtual-cards',
          hideDelay: 4000
        });
      }
      this.acpCoreDispatcher.virtualCard.virtualCardApiFail.emit();
      throw error;
    }
  }

  private handleListener(): void {
    this.$onValue(this.acpCoreDispatcher.virtualCard.virtualCardsUpdate, () => {
      this.redirectTo().then((isLanding) => {
        this.isLanding = isLanding;
      });
    });
  }

  private async virtualCardSection(): Promise<boolean> {
    const isDirectCDDA: boolean = await this.nsPermissions.requestPermission(
      'isDirectCDDA'
    );
    const isCDDAEligibleForOrderCard: boolean = await this.nsPermissions.requestPermission(
      'isCDDAEligibleForOrderCard'
    );
    if (isDirectCDDA) {
      return isCDDAEligibleForOrderCard;
    } else return true;
  }

  // These are needed to satisfy TSC. The real implementation comes from `nsComponentDecorator(this, this)`
  // public $tie?<T>(property: string, signal: nsUtils.NsSignal<T>);
  // public $tiePermissions?(property: string, permissions: string[]);
}
// defined custom validation class in ES5 style
// as transpiled ES6 class causing issue while using instanceof operator.
function VirtualCardNotSupported(message) {
  Error.call(this, message);
}
VirtualCardNotSupported.prototype = Object.create(Error.prototype);
VirtualCardNotSupported.prototype.constructor = VirtualCardNotSupported;

export const acpVirtualCardContainerComponent: ng.IComponentOptions = {
  bindings: {}, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpVirtualCardContainerComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpVirtualCardContainerTemplate
};
