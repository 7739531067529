import { AcpApptentivePlugin } from 'components/apptentive-domain';
import { AcpAnalytics } from 'core';
import acpVirtualCardAddTemplate from './templates/acp-virtual-cards-add.html';

export class AcpVirtualCardAddComponentCtrl
  implements nsUtils.NsComponentController {
  public busy: boolean = false;
  public editNickName: boolean;
  public nickname: string;
  public cardId: string;
  public isVirtualCardOrderAllowed: boolean;
  constructor(
    nsComponentDecorator,
    private acpVirtualCardsModel: any,
    private acpCoreDispatcher: any,
    private $mdDialog: angular.material.IDialogService,
    private $mdBottomSheet: angular.material.IBottomSheetService,
    private acpAnalytics: AcpAnalytics,
    private nsInPageFlow: any,
    private nsPermissions: nsUtils.NsPermissionsService,
    private acpApptentivePlugin: AcpApptentivePlugin
  ) {
    'ngInject';

    // If methods such as $onValue and $tie are needed:
    nsComponentDecorator(this, this);
  }

  public async $onInit() {
    // Perform initialization here
    await this.requestPermissions();
  }

  public gaTrackingEvent(gaAction): void {
    // Trigger GA event if user is coming to create new account
    if (!this.editNickName) {
      this.acpAnalytics.sendEvent(
        'ACP_Virtual_Accounts',
        gaAction,
        'Create_Virtual_Account'
      );
    }
  }

  public async createVirtualCard(): Promise<void> {
    if (this.busy) {
      return;
    }
    this.busy = true;
    try {
      if (this.editNickName) {
        return await this.updateCardName();
      }
      await this.addVirtualCard();
      if (this.nsInPageFlow.isOpen()) {
        this.nsInPageFlow.close();
      }
      try {
        this.acpApptentivePlugin.sendEvent('Create_virtual_card_success');
      } catch (error) {
        this.acpApptentivePlugin.errorLog(
          'AcpVirtualCardAddComponentCtrl',
          error
        );
      }
      this.gaTrackingEvent('Create_Account_Clicked');
    } catch (error) {
      this.createVirtualCardErrorCallback();
    } finally {
      this.busy = false;
      this.resetComponent();
    }
  }

  public $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);

  private createVirtualCardErrorCallback(): void {
    this.acpCoreDispatcher.toast.show.emit({
      toastClass: 'failure vc-nickname-failure',
      content: 'virtual-card-something-wrong:components/virtual-cards',
      hideDelay: 4000
    });
  }

  private async addVirtualCard(): Promise<void> {
    await this.acpVirtualCardsModel.createVirtualCard(this.nickname);
    await this.acpVirtualCardsModel.resetVirtualInfo();
    this.showMessage('virtual-card-added-toast', 'success');
  }

  private async updateCardName(): Promise<void> {
    await this.acpVirtualCardsModel.updateNickname(this.nickname, this.cardId);
    await this.acpVirtualCardsModel.resetVirtualInfo();
    this.acpCoreDispatcher.virtualCard.virtualCardNicknameUpdated.emit({
      updatedNickname: this.nickname
    });
    this.showMessage('nickname-saved-toast', 'success');
  }

  private resetComponent(): void {
    this.$mdDialog.hide();
    this.$mdBottomSheet.hide();
  }

  private showMessage(key: string, type: string): void {
    this.acpCoreDispatcher.toast.show.emit({
      toastClass: type,
      content: `${key}:components/virtual-cards`,
      hideDelay: 4000
    });
  }
  private requestPermissions(): Promise<nsUtils.NsPermissionResult> {
    return this.nsPermissions
      .requestPermissions(['isVirtualCardOrderAllowed'])
      .then(this.updatePermissions.bind(this));
  }

  private updatePermissions(permissions?: nsUtils.NsPermissionResult): void {
    this.isVirtualCardOrderAllowed = permissions.isVirtualCardOrderAllowed;
  }
  // These are needed to satisfy TSC. The real implementation comes from `nsComponentDecorator(this, this)`
  // public $tie?<T>(property: string, signal: nsUtils.NsSignal<T>);
  // public $tiePermissions?(property: string, permissions: string[]);
}

export const acpVirtualCardAdd: ng.IComponentOptions = {
  bindings: {
    close: '&',
    editNickName: '<',
    cardId: '<',
    nickname: '<'
  }, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpVirtualCardAddComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpVirtualCardAddTemplate
};
